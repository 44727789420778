import React from "react"
import { Link } from "gatsby"

class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-black">
        <div id="footer-content">
          <div className="container">
            <h4 className="text-white mb-4">EarthSeeds Project</h4>
            <div className="row">
              <div className="col-md-5 pr-md-5">
                <p className="text-white">
                  EarthSeeds was conceived by a forest ranger looking for a
                  better way to teach young people about our planet. In 1997, he
                  met a partner and together they brought Spaceship Earth
                  Foundation to Colorado. Within a couple of years, The
                  EarthSeeds Project was born as GVFs primary program. 
                </p>
                <Link
                  className="btn btn-link hvr-shadow mb-3 mr-md-3"
                  to="/earthseeds-project"
                >
                  Planet Earth Gifts
                </Link>
              </div>
              <div className="col-md-4">
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/planet-in-every-classroom">
                      Planet in Every Classroom
                    </Link>
                  </li>
                  <li>
                    <Link to="/flying-the-earth-flag">
                      Flying the Earth Flag
                    </Link>
                  </li>
                  <li>
                    <Link to="/earth-in-every-church">
                      Earth in Every Church
                    </Link>
                  </li>
                  <li>
                    <Link to="/earthday-2020">What we’re Doing</Link>
                  </li>
                  <li>
                    <a href="https://www.earthday.org/">EarthDay.org</a>
                  </li>
                  <li>
                    <a
                      href="https://earthcharter.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Earth Charter
                    </a>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/earth-gallery">Earth Gallery</Link>
                  </li>
                  <li>
                    <Link to="/earth-downloads">Earth Downloads</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/allies">Allies</Link>
                  </li>
                  <li>
                    <Link className="d-none" to="/archive">
                      Archives
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact GVF</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <ul className="list-unstyled footer-info mb-4">
                  <li>
                    <span>Global View Foundation</span>
                  </li>
                  <li>
                    <span>Longmont, Colorado, USA</span>
                  </li>
                  <li>
                    <a href="mailto:fundraising@earthseeds.org">
                      fundraising@earthseeds.org
                    </a>
                  </li>
                  <li>
                    <a href="tel://7208180361">720-818-0361</a>
                  </li>
                  <li />
                </ul>
                {/*<a
                  href="https://www.facebook.com/TheEarthSeedsProject/"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                ></a>*/}
              </div>
            </div>
          </div>
        </div>
        <div id="copyright" className="py-4 bg-black">
          <div className="container">
            <p className="mb-0 text-dirty-white">
              © Copyright 2004 - {new Date().getFullYear()} - The Global View
              Foundation & The EarthSeeds Project - All Rights Reserved -
              Designed by EclipticImagery.com and Powered by{}{" "}
              <a
                className="text-white"
                title="Footer link"
                href="http://webriq.com"
                target="_blank"
                alt="WebriQ"
                rel="nofollow noreferrer noopener"
              >
                WebriQ
              </a>
            </p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
