import React from "react"
import { Link } from "gatsby"
import Sticky from "react-sticky-el"
import SideBarMenu from "../sidebar/sidebar"
import HorizontalMenu from "../sidebar/horizontalmenu"
import styl from "./header.module.css"
// import Swiper from "../swiper/swiper"


const Header = () => (
  <header>
  
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={styl.menuContainer}>
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-9 col-md-2">
                <div className={styl.navBrand}>
                <Link to="/">
                  <img
                    className="img-fluid"
                    src="/assets/img/earthseeds-logo.png"
                    width={100}
                    alt="logo"
                  />
                  </Link>
                </div>
              </div>
              <div className="col-2 col-md-10 ml-auto">
                <HorizontalMenu/>
                <div className="sidebar-menu d-lg-none d-block">
                  <SideBarMenu/>  
                </div>
              </div>
            </div>
          </div>
        </div>
    </Sticky>
  </header>
)

export default Header
