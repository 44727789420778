import React from "react"
import { Link } from "gatsby"
import { Nav, Dropdown, NavItem } from "react-bootstrap"
import "./menu.css"

const horizontalMenu = () => {
  return (
    <div className="horizontal-menu d-none d-lg-inline">
      <Nav className="main-nav d-flex align-items-center justify-content-end">
        <Nav.Item className="mr-2">
          <Link to="/">HOME</Link>
        </Nav.Item>

        <Dropdown as={NavItem}>
          <Dropdown.Toggle variant="dropdown-basic">
            EARTHSEEDS PROJECT
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to="/earthseeds-project">
              Earthseeds Project
            </Link>
            <Link className="dropdown-item" to="/planet-in-every-classroom">
              Planet in Every Classroom
            </Link>
            <Link className="dropdown-item" to="/flying-the-earth-flag">
              Flying the Earth Flag
            </Link>
            <Link className="dropdown-item" to="/earth-in-every-church">
              Earth in Every Church
            </Link>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={NavItem}>
          <Dropdown.Toggle variant="dropdown-basic">
            EARTHDAY 2020
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to="/earthday-2020">
              EARTHDAY 2020
            </Link>
            <Link className="dropdown-item" to="/earthday-2020">
              What we’re Doing
            </Link>
            <a
              className="dropdown-item"
              href="https://www.earthday.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              EarthDay.org
            </a>
            <a
              className="dropdown-item"
              href="https://earthcharter.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Earth Charter
            </a>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={NavItem}>
          <Dropdown.Toggle variant="dropdown-basic">
            FUNDRAISING STORE
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <a
              className="dropdown-item"
              href="https://shop.earthseeds.org/products"
            >
              Shop
            </a>
            <a
              className="dropdown-item"
              href="https://shop.earthseeds.org/index.php?rt=account/login"
            >
              My Account
            </a>
            <a
              className="dropdown-item"
              href="https://shop.earthseeds.org/index.php?rt=checkout/cart"
            >
              Cart
            </a>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={NavItem}>
          <Dropdown.Toggle variant="dropdown-basic">RESOURCES</Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to="/about-us">
              About Us
            </Link>
            <Link className="dropdown-item" to="/earth-gallery">
              Earth Gallery
            </Link>
            <Link className="dropdown-item" to="/earth-downloads">
              Earth Downloads
            </Link>
            <Link className="dropdown-item" to="/blog">
              Blogs
            </Link>
            <Link className="dropdown-item" to="/allies">
              Allies
            </Link>
            <Link className="dropdown-item d-none" to="/archive">
              Archives
            </Link>
            <Link className="dropdown-item" to="/contact">
              Contact GVF
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </div>
  )
}

export default horizontalMenu
