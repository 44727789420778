import React from "react";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "animate.css/animate.min.css";
import "./hero.css"

class Hero extends React.Component {
  render() {
    const { location, title } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let hero;
    if(location.pathname === rootPath) {
      hero = (
        <div className="hero">
        <div className="hero-bg-container">
        <LazyLoadComponent>
          <iframe
            title="earth"
            allowFullScreen
            frameBorder={0}
            src="https://player.vimeo.com/video/370776572?autoplay=1&muted=1&loop=1&portrait=0&title=0&byline=0"
            width={560}
            height={315}
            webkitallowfullscreen
            mozallowfullscreen
          />
          </LazyLoadComponent>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <div className="hero-content text-center">
              <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

               <h1 className="text-capitalize text-white" id="hero-title">
                  PRESERVE & CELEBRATE OUR ESSENTIAL COMMON GROUND
                </h1>
                <h3 className="text-white px-md-5" id="hero-subtitle">
                  Bringing Astronaut Views Down to Earth
                </h3>
                
                <div className="d-flex justify-content-center mt-5">
                  <Link className="btn btn-donate hvr-shadow" to="/earthseeds-project">
                    DONATE TO RECEIVE
                    <span>EARTH ART AND GIFTS</span>
                  </Link>
                </div>

              </ScrollAnimation>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }
    else {
      hero=(
        <div className="hero-main">
          <div className="hero-main-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="page-title text-white">{title}</h1>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <>
      {hero}
      </>
      
    );
  }
}

export default Hero;
