import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/header"
import Hero from "./hero/"
import Footer from "./footer/footer"
import "./layout.css"
import "bootstrap/dist/css/bootstrap.css"
import "./font-face.css"

const Layout = ({ children, location, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <div id="index">
      <Header siteTitle={data.site.siteMetadata.title} />
      <Hero location={location} title={title}/>
      <main>{children}</main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
