import React from "react"
import { Link } from "gatsby"
import { Nav, Dropdown, NavItem } from "react-bootstrap"
import "./menu.css"

export default () => (
  <div className="navigation">
    {/* <div className="nav-brand-side text-center bg-white">
        //   <img
        //     className="img-fluid"
        //     src="/assets/img/earthseeds-logo.png"
        //     alt="logo"
        //   />
        // </div>*/}
    <Nav className="main-nav d-block">
      <Nav.Item>
        <Link to="/">HOME</Link>
      </Nav.Item>
      <Dropdown as={NavItem}>
        <Link variant="success" className="float-left" to="/earthseeds-project">
          EARTHSEEDS PROJECT
        </Link>
        <Dropdown.Toggle split id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Link className="dropdown-item" to="/planet-in-every-classroom">
            Planet in Every Classroom
          </Link>
          <Link className="dropdown-item" to="/flying-the-earth-flag">
            Flying the Earth Flag
          </Link>
          <Link className="dropdown-item" to="/earth-in-every-church">
            Earth in Every Church
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem}>
        <Link variant="success" className="float-left" to="/earthday-2020">
          EARTHDAY 2020
        </Link>
        <Dropdown.Toggle split id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Link className="dropdown-item" to="/earthday-2020">
            What we’re Doing
          </Link>
          <a
            className="dropdown-item"
            href="https://www.earthday.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            EarthDay.org
          </a>
          <a
            className="dropdown-item"
            href="https://earthcharter.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Earth Charter
          </a>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle variant="dropdown-basic">
          FUNDRAISING STORE
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <a
            className="dropdown-item"
            href="https://shop.earthseeds.org/products"
          >
            Shop
          </a>
          <a
            className="dropdown-item"
            href="https://shop.earthseeds.org/index.php?rt=account/login"
          >
            My Account
          </a>
          <a
            className="dropdown-item"
            href="https://shop.earthseeds.org/index.php?rt=checkout/cart"
          >
            Cart
          </a>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle variant="dropdown-basic">RESOURCES</Dropdown.Toggle>
        <Dropdown.Menu>
          <Link className="dropdown-item" to="/about-us">
            About Us
          </Link>
          <Link className="dropdown-item" to="/earth-gallery">
            Earth Gallery
          </Link>
          <Link className="dropdown-item" to="/earth-downloads">
            Earth Downloads
          </Link>
          <Link className="dropdown-item" to="/blog">
            Blogs
          </Link>
          <Link className="dropdown-item" to="/allies">
            Allies
          </Link>
          <Link className="dropdown-item d-none" to="/archive">
            Archives
          </Link>
          <Link className="dropdown-item" to="/contact">
            Contact GVF
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
    <ul className="list-unstyled d-flex flex-column justify-content-end align-content-center m-0 pl-5">
      <li className="mb-2">
        <a className="text-white" href="mailto:fundraising@earthseeds.org">
          <img
            className="mr-2 mb-0"
            src="/assets/img/001-worldwide.svg"
            width={18}
            height={18}
            alt="email"
          />
          fundraising@earthseeds.org
        </a>
      </li>
      <li>
        <a className="text-white" href="tel://7208180361">
          <img
            className="mr-1 mb-0"
            src="/assets/img/002-phone.svg"
            width={18}
            height={18}
            alt="phone"
          />
          720 - 818 - 0361
        </a>
      </li>
    </ul>
  </div>
)
